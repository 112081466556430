<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <div class="list-group" id="list-tab" role="tablist">
          <li
            class="list-group-item   list-group-item-secondary d-flex justify-content-between align-items-center"
          >
            Bancos
          </li>
          <a
            class="list-group-item list-group-item-action active"
            id="list-home-list"
            data-toggle="list"
            href="#list-home"
            role="tab"
            aria-controls="home"
            @click="showBancos = 1"
            ><strong>Pilotos</strong></a
          >
          <a
            class="list-group-item list-group-item-action"
            id="list-profile-list"
            data-toggle="list"
            href="#list-profile"
            role="tab"
            aria-controls="profile"
            @click="showBancos = 2"
            ><strong>Consejeras</strong></a
          >
        </div>
      </div>
      <!-- CUENTAS BANCARIAS PARA PILOTOS -->
      <bancosPilotos v-if="showBancos == 1"></bancosPilotos>
      <!-- CUENTAS BANCARIAS PARA PILOTOS -->

      <!-- CUENTAS BANCARIAS PARA CONSEJERAS -->
      <bancosConsejeras v-if="showBancos == 2"></bancosConsejeras>
      <!-- CUENTAS BANCARIAS PARA CONSEJERAS -->
    </div>
  </div>
</template>
<style></style>
<script>
import bancosPilotos from "./bancosPilotos.vue";
import bancosConsejeras from "./bancosConsejeras.vue";
export default {
  components: {
    bancosPilotos,
    bancosConsejeras
  },
  data() {
    return {
      showBancos: 1
    };
  }
};
</script>
