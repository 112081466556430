<template>
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h5
          class="modal-title"
          title="mantenimiento de cuentas bancarias en donde depositan los pilotos"
        >
          Listado de cuentas bancarias para pilotos
        </h5>
        <p>Cuentas bancarias para depósitos/transferencias de pilotos</p>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col col-8">
            <p>Crear una nueva cuenta bancaria</p>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Banco</th>
                  <th scope="col">Número de cuenta</th>
                  <th scope="col">crear</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      v-model="newBanco.banco"
                      class="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      v-model="newBanco.cuenta"
                      class="form-control"
                    />
                  </td>
                  <td>
                    <button
                      @click="fnCrearCuenta"
                      title="Agregar cuenta bancaria"
                      type="button"
                      class="btn btn-sm btn-success btn-outline"
                    >
                      <span class="mdi mdi-plus"></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col col-12">
            <p>Cuentas bancarias existentes</p>
            <v-server-table
              :columns="columns"
              :options="optionsVTable"
              ref="tblPagoDeCuentasPilotos"
              id="tblPagoDeCuentasPilotos"
            >
              <div slot="opciones" class="text-center" slot-scope="props">
                <!-- BOTON PARA EDITAR NUMERO DE CUENTA -->
                <button
                  @click="fnUpdateCurrentBancoEdit(props.row)"
                  data-toggle="modal"
                  data-target="#modalEditar"
                  class="btn btn-sm btn-warning btn-outline mr-1"
                >
                  <span class="mdi mdi-pencil"></span>
                </button>
                <!-- BOTON PARA EDITAR NUMERO DE CUENTA -->
                <!-- BOTON PARA ELIMINAR NUMERO DE CUENTA -->
                <button
                  @click="currentBanco = props.row"
                  data-toggle="modal"
                  data-target="#ModalEliminar"
                  class="btn btn-sm btn-danger btn-outline"
                >
                  <span class="mdi mdi-trash-can-outline"></span>
                </button>
                <!-- BOTON PARA ELIMINAR NUMERO DE CUENTA -->
              </div>
            </v-server-table>
          </div>
        </div>
      </div>
    </div>
    <!-- /** DIALOG PARA EDITIAR */ -->
    <div
      class="modal fade"
      id="modalEditar"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalEditarLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document" style="max-width: 1215px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalEditarLabel">
              ¿Deseas editar la inofmración de esta cuenta bancaria para
              pilotos?
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <label for="editarBancoNombre"
                  >Actual: {{ currentBanco.banco }}</label
                >
                <input
                  id="editarBancoNombre"
                  type="text"
                  v-model="editBanco.banco"
                  class="form-control"
                  placeholder="Editar Banco"
                />
              </div>
              <div class="col">
                <label for="editarBancoCuenta"
                  >Actual: {{ currentBanco.cuenta }}</label
                >
                <input
                  id="editarBancoCuenta"
                  type="text"
                  v-model="editBanco.cuenta"
                  class="form-control"
                  placeholder="Editar Cuenta"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              ref="botonCerrarModalEditar"
              type="button"
              class="btn-sm btn btn-secondary"
              data-dibtn-sm
              smiss="modal"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              @click="fnEditarCuenta"
              type="button"
              class="btn-sm btn btn-primary"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /** DIALOG PARA EDITIAR */ -->
    <!-- /** DIALOG PARA ELIMINAR */ -->
    <div
      class="modal fade"
      id="ModalEliminar"
      tabindex="-1"
      role="dialog"
      aria-labelledby=""
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document" style="max-width: 815px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ModalEliminarLabel">
              ¿Deseas eliminar la inofmración de esta cuenta bancaria para
              pilotos?
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <p class="d-flex justify-content-center">
                  Número de cuenta a eliminar:
                  <strong class="ml-1"
                    >{{ currentBanco.banco }} -
                    {{ currentBanco.cuenta }}</strong
                  >
                </p>
                <p class="d-flex justify-content-center">
                  <small
                    >La eliminación de esta cuenta es permanente y no se puede
                    revertir</small
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="fnEliminarCuenta()"
              type="button"
              class="btn-sm btn btn-danger"
            >
              Eliminar
            </button>
            <button
              ref="botonCerrarModalEliminar"
              type="button"
              class="btn-sm btn btn-secondary"
              data-dibtn-sm
              smiss="modal"
              data-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /** DIALOG PARA ELIMINAR */ -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      newBanco: {
        banco: null,
        cuenta: null,
      },
      currentBanco: {
        banco: null,
        cuenta: null,
      },
      editBanco: {
        banco: null,
        cuenta: null,
      },
      /** v server table */
      columns: ["id", "banco", "cuenta", "opciones"],
      optionsVTable: {
        view_total_de_cuentas: 0,
        orderBy: {
          column: "id",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: [""],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: ["id", "banco", "cuenta"],
        headings: {
          banco: "Banco",
          cuenta: "Cuenta",
          opciones: "Opciones",
        },
        cellClasses: {},
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          // search += `, "reportebultosvalidados_id": "${this.idDelReporte}"`;
          if (data.query) {
            /** FILTROS STANDAR TABLA DE PAGOS */
            //
            if (data.query["id"]) {
              search += `, "id": "${data.query["id"]}"`;
            }
            //
            if (data.query["banco"]) {
              search += `, "banco": "${data.query["banco"]}"`;
            }
            //
            if (data.query["cuenta"]) {
              search += `, "cuenta": "${data.query["cuenta"]}"`;
            }
            //
          }

          /** FILTROS POR CONDICIÓN / ESTADO DEL PAGO */

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/pago-cuentas-pilotos`;

          return this.$http.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt"),
            },
          });
        },
        responseAdapter(resp) {
          //asignar total de cuentas dentro de la base de datos
          this.options.view_total_de_cuentas = resp.data.total;
          //fin asignar total de cuentas dentro de la base de datos

          return {
            data: resp.data.data,
            count: resp.data.total,
          };
        },
        rowClassCallback(row) {}, //Using Boostrap4
      },
    };
  },
  methods: {
    ...mapActions("PagoCuentasPilotos", {
      findPagoCuentasPilotos: "find",
      createPagoCuentasPilotos: "create",
      patchPagoCuentasPilotos: "patch",
      removePagoCuentasPilotos: "remove",
    }),
    fnCrearCuenta() {
      console.log("la cucha");
      this.createPagoCuentasPilotos(this.newBanco).then(() => {
        this.newBanco.banco = null;
        this.newBanco.cuenta = null;
        this.$refs.tblPagoDeCuentasPilotos.refresh();
      });
    },
    fnUpdateCurrentBancoEdit(infoBanco) {
      this.currentBanco = infoBanco;
      this.editBanco.banco = infoBanco.banco;
      this.editBanco.cuenta = infoBanco.cuenta;
    },
    fnEditarCuenta() {
      this.patchPagoCuentasPilotos([
        this.currentBanco.id,
        { banco: this.editBanco.banco, cuenta: this.editBanco.cuenta },
      ]).then(() => {
        this.currentBanco.banco = null;
        this.currentBanco.cuenta = null;
        this.editBanco.banco = null;
        this.editBanco.cuenta = null;
        this.$refs.botonCerrarModalEditar.click();
        this.$refs.tblPagoDeCuentasPilotos.refresh();
      });
    },
    fnEliminarCuenta() {
      this.removePagoCuentasPilotos(this.currentBanco.id).then(() => {
        this.$refs.botonCerrarModalEliminar.click();
        this.$refs.tblPagoDeCuentasPilotos.refresh();
        this.currentBanco.banco = null;
        this.currentBanco.cuenta = null;
      });
    },
  },
};
</script>
